import { React, useState, useEffect, useRef } from 'react'
import { Toast, Result, ProgressBar } from 'antd-mobile'
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LeftOutline, SmileOutline } from 'antd-mobile-icons'
import { getInfo, getType } from '../request/api'
import './ProgressBox.css'

export default function Check() {
    const { t, i18n } = useTranslation();
    const [type, setType] = useState(0)
    const [progress, setProgress] = useState(0);
    const location = useLocation();
    let navigate = useNavigate();
    if (new URLSearchParams(location.search).get('chat_id')) {
        localStorage.setItem('chat_id', new URLSearchParams(location.search).get('chat_id'))
        localStorage.setItem('username', new URLSearchParams(location.search).get('username'))
    }

    const getData = async () => {
        let chat_id = ""
        if (new URLSearchParams(location.search).get('chat_id')) {
            chat_id = new URLSearchParams(location.search).get('chat_id')
        } else {
            chat_id = localStorage.getItem('chat_id')
        }
        const res = await getType({
            username: chat_id
        })
        switch (res.code) {
            case 102:
                localStorage.setItem('token', res.data)
                navigate('/User')
                setType(1)
                break;
            case 101:
                setType(2)
                break;
            case 103:
                setType(3)
                break;
            case 1:
                navigate('/Register')
                break;
        }
    }
    const outWebview = () => {
        var url = "https://www.tiikapi.com/User";
        if (window.android && typeof window.android.goCustomerService === 'function') {
            navigate('/User', { replace: true })
            window.android.goCustomerService(url);
        } else {
            console.log("android is not available.");
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (progress < 100) {
                setProgress((prevProgress) => prevProgress + 2);
            } else {
                getData()
                clearInterval(interval);
            }
        }, 50);

        return () => {
            clearInterval(interval);
        };
    }, [progress]);
    return (
        <div className='ProgressBox'>
            {
                progress != 100 && <p className='ploading'>Loading...</p>
            }
            {progress != 100 && <ProgressBar
                text
                className='Progress'
                percent={progress}
                style={{
                    '--fill-color': '#f73869',
                }}
            />}
            {type == 2 && <Result
                status='waiting'
                title={t('t29')}
            />}
            {/* {type == 2 && <p className='backBtn' onClick={outWebview}>{t('t26')}</p>} */}
            {type == 3 && <Result
                status='error'
                title={t('t30')}
            />}
        </div >
    )
}
