import { React, useState, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import { ExclamationShieldOutline, LeftOutline } from 'antd-mobile-icons'
import '../Pay/pay.css'
import _ from 'lodash'
import { recharge } from '../../request/api'
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

export default function UserPay() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [val, setVal] = useState('')
  const getData = async () => {
    const res = await recharge({})
    setVal(res.data)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='UserPay'>
      <div className='header'>
        <div className='backIcon' onClick={() => {
          var url = "https://www.tiikapi.com/User";
          if (window.android && typeof window.android.goCustomerService === 'function') {
            navigate('/User', { replace: true })
            window.android.goCustomerService(url);
          } else {
            console.log("android is not available.");
          }
        }}>
          <LeftOutline />
        </div>
        <p>{t('g2')}</p>
      </div>
      <div style={{ height: '50px' }}></div>
      <div className='UserPay_box'>
        <div className='userPay_top'>
          <img src="/assets/index/trc20.png" alt="" />
          <p>TRC-20</p>
        </div>
        <div className='qrcode'>

          <div className='code'>
            <QRCode
              id={'qrCode'}
              value={val}  //value参数为生成二维码的链接
              size={170} //二维码的宽高尺寸
              fgColor="#000000"  //二维码的颜色
            />
          </div>
          <div className='payCode'>
            <p>{val}</p>
          </div>
          <div className='copy' onClick={() => {
            const textField = document.createElement('textarea');
            textField.value = val;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            Toast.show({
              icon: 'success',
              content: t('t2'),
            })
            // navigator.clipboard.writeText(val).then(() => {
            //   Toast.show({
            //     icon: 'success',
            //     content: t('t2'),
            //   })
            // });
          }}>
            <p>{t('cz2')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
