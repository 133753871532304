import { React, useEffect, useState } from 'react'
import '../views/register.css'
import { DownOutline } from 'antd-mobile-icons'
import { Picker, Button, Toast } from 'antd-mobile'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { register } from '../request/api'


export default function Register() {
    let navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [areaNum, setAreaNum] = useState(1)
    const [visible, setVisible] = useState(false)//选择器显示
    const [input1Value, setInput1Value] = useState(localStorage.getItem('chat_id') || new URLSearchParams(location.search).get('chat_id'));
    const [input2Value, setInput2Value] = useState(localStorage.getItem('username') || new URLSearchParams(location.search).get('username'));
    const [input3Value, setInput3Value] = useState();
    const [input5Value, setInput5Value] = useState("");
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const chatId = searchParams.get('chat_id');
        const username = searchParams.get('username');
        setInput1Value(chatId || localStorage.getItem('chat_id'));
        setInput2Value(username || localStorage.getItem('username'));
    }, [location])
    const [list1, setlist1] = useState([
        [
            { label: '中文', value: 'zh' },
            { label: 'English', value: 'en' },
            { label: 'Español', value: 'es' },
            { label: 'Polski', value: 'pl' },
            { label: 'čeština', value: 'cs' },
            { label: 'Dansk', value: 'da' },
            { label: 'Ελληνικά', value: 'el' },
            { label: 'Română', value: 'ro' },
            { label: 'Slovenský', value: 'sk' },
            { label: 'Deutsch', value: 'de' },
            { label: 'Italiano', value: 'it' },
            { label: 'やまと', value: 'jp' },
            { label: 'Türkçe', value: 'tr' },
            { label: 'العربية', value: 'ar' },
            { label: 'Nederlands', value: 'nl' },
            { label: 'বাংল', value: 'bn' },
            { label: '한국인', value: 'kr' },
            { label: 'русский язык', value: 'ru' },
        ],
    ])
    const submit = () => {
        const inputValues = [input1Value, input2Value, input5Value];
        if (inputValues.some(value => value === '' || value === null)) {
            Toast.show({
                icon: 'fail',
                content: t('t3'),
            });
            return false;
        }
        //10位
        if (input3Value.length < 10) {
            Toast.show({
                icon: 'fail',
                content: t('l17'),
            });
            return false;
        }
        Toast.show({
            icon: 'loading',
            content: t('t4'),
        })
        register({
            nickname: input2Value,
            username: input1Value,
            phone: input3Value,
            invite: input5Value,
        }).then(res => {
            if (res.code == 1) {
                Toast.show({
                    icon: 'success',
                    content: res.info,
                })
                setTimeout(() => {
                    navigate('/')
                }, 1000);
            } else {
                Toast.show({
                    icon: 'fail',
                    content: res.info,
                })
            }
        })
    }
    return (
        <div className='Register'>
            {/* <div className='backUser' onClick={() => { navigate('/') }}>
                <p>{t('t26')}</p>
            </div> */}
            <div className='lang' onClick={() => { setVisible(true) }}>
                <p>{t('l1')}</p>
            </div>
            <div className='from_Register'>
                <div className='logo'>
                    <img src="/assets/index/logo.png" alt="" />
                </div>
                <div className='Register_from'>
                    {false && <div className='input_from'>
                        <input className='account' disabled type="number" value={input1Value || ''} onChange={(e) => setInput1Value(e.target.value)} placeholder="" />
                    </div>}
                    <div className='input_from'>
                        <input className='account' disabled type="text" value={input2Value || ''} onChange={(e) => setInput2Value(e.target.value)} placeholder="" />
                    </div>
                    <div className='input_from'>
                        <input className='account' type="number" value={input3Value || ''} onChange={(e) => setInput3Value(e.target.value)} placeholder={t('l8')} />
                    </div>
                    {/* <div className='input_from'>
                        <input className='password' type="password" onChange={(e) => setInput3Value(e.target.value)} placeholder={t('l16')} />
                    </div> */}
                    {/* <div className='input_from'>
                        <input className='password' type="password" onChange={(e) => setInput4Value(e.target.value)} placeholder={t('l11')} />
                    </div> */}
                    <div className='input_from'>
                        <input className='password' type="number" value={input5Value || ''} onChange={(e) => setInput5Value(e.target.value)} placeholder={t('l12')} />
                    </div>
                    <div className='Register_submit'>
                        <Button block color='' onClick={() => { submit() }}>
                            {t('l13')}
                        </Button>
                    </div>
                    <div style={{ height: '15px' }}></div>
                    {/* <div className='Register_text'>
                        <p>{t('l14')}?</p><p onClick={() => { navigate('/Login') }}>{t('l15')}</p>
                    </div> */}
                </div>
            </div>
            <Picker
                columns={list1}
                defaultValue={[localStorage.getItem('lang') || 'en']}
                cancelText={t('ty2')}
                confirmText={t('ty1')}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                onConfirm={v => {
                    i18n.changeLanguage(v[0])
                    localStorage.setItem('lang', v[0])
                }}
            />
        </div>
    )
}
