import { React, useState, useEffect, useRef } from 'react'
import { Toast, Result, ProgressBar, ErrorBlock } from 'antd-mobile'
import { useNavigate, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import './ProgressBox.css'

export default function Check() {
    const { t, i18n } = useTranslation();

    useEffect(() => {

    }, []);
    return (
        <div className='error'>
            <ErrorBlock fullPage status='default' description={
                <span>
                    {t('t33')}
                </span>
            } />
        </div >
    )
}
