import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BrowserBackListener = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handlePopstate = () => {
            // console.log(window.location.pathname);
            // console.log('浏览器回退事件发生了');
            // navigate('/User', { replace: true })

            var url = "https://www.tiikapi.com/User";
            if (window.android && typeof window.android.goCustomerService === 'function') {
                navigate('/User', { replace: true })
                window.android.goCustomerService(url);
            } else {
                console.log("android is not available.");
            }
        };

        window.addEventListener('popstate', handlePopstate, false);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    return null;
};

export default BrowserBackListener;