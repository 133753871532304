//阿拉伯
export const ar = {
    //شريط التنقل
    'n1': 'الصفحة الرئيسية',
    'n2': 'الطلب',
    'n3': 'معاملة',
    'n4': 'فريق',
    'n5': 'بلدي',
    // فئة سريعة
    't1': 'حدث خطأ',
    't2': 'تم النسخ بنجاح',
    't3': 'يُرجى ملء المعلومات بالكامل',
    't4': 'جارٍ التحميل...',
    't5': 'تم تسجيل الدخول بنجاح',
    't6': 'يجب أن تكون النتيجة أكبر من 3 للإرسال',
    't7': 'تم الإرسال بنجاح',
    't8': 'الرصيد غير كافي, غير قادر على الاستلام',
    't9': 'فشل الإرسال',
    't10': 'الرجاء ربط العنوان أولاً, وسيتم إعادة توجيهك إلى صفحة المركز الشخصية',
    't11': 'تم ربط العنوان, يرجى الاتصال بخدمة العملاء لتعديله',
    't12': 'تم التعديل بنجاح',
    't13': 'فشل التعديل',
    't14': 'يُرجى الاتصال بخدمة العملاء لتغيير كلمة المرور الخاصة بك',
    't15': 'خطأ في تنسيق كلمة المرور',
    't16': 'السحب ناجح',
    't17': 'الحد الأقصى لعمليات السحب',
    't18': 'الحد الأدنى للسحب',
    't19': 'كلمتا مرور غير متناسقتين',
    't20': "مطالبة",
    't21': 'لا يمكن القفز مؤقتًا',
    't22': 'خطأ في تنسيق كلمة المرور',
    't23': 'تم التسجيل بنجاح',
    't24': 'الرجاء إدخال رمز المنطقة',
    't25': 'لا أكثر',
    't26': 'يعود',
    't27': 'مراجعة',
    't28': 'نجحت عملية التدقيق',
    't29': 'في انتظار المراجعة والمعالجة',
    't30': 'فشل التدقيق',
    't31': 'الرجاء ملء العنوان',
    't32': 'الرجاء إدخال لقبك',
    't33': "حدث خطأ. الرجاء الخروج من البرنامج والمحاولة مرة أخرى. ",
    't34': 'تم اكتشاف مؤخرًا أن العديد من الأشخاص يتظاهرون بأنهم Tiktok لارتكاب عمليات احتيال، يرجى البحث عن تطبيقنا الرسمي',
    //اشتراك
    'l1': 'اللغة',
    'l2': 'الرجاء إدخال رقم حسابك',
    'l3': 'الرجاء إدخال كلمة المرور',
    'l4': 'تذكر',
    'l5': 'تسجيل الدخول',
    'l6': 'اذهب إلى التسجيل',
    'l7': 'لا يوجد حساب',
    'l8': 'رقم الهاتف المحمول',
    'l9': 'اللقب',
    'l10': 'كلمة المرور',
    'l11': 'تأكيد كلمة المرور',
    'l12': 'رمز الدعوة',
    'l13': 'تسجيل',
    'l14': 'لديك حساب بالفعل',
    'l15': 'اذهب لتسجيل الدخول',
    'l16': 'الرجاء إدخال كلمة مرور مكونة من 6 إلى 16 رقمًا',
    'l17': 'خطأ في تنسيق الرقم',

    //يساعد
    'bz1': 'مساعدة',
    'bz2': 'الأسئلة الشائعة',
    'bz3': 'قواعد التداول',
    'bz4': 'كيفية سحب الأموال',
    'bz5': 'كيفية إعادة الشحن',
    'bz6': "1.ما هو TiktokShop؟",
    'bz7': "TiktokShop هي شركة تسويق وترويج تساعد تجار متجر TiktokShop عبر الإنترنت حول العالم في الحصول على المزيد من مبيعات الطلبات وزيادة بيانات تصفح متجر TiktokShop عبر الإنترنت. نحن ملتزمون بإنشاء نموذج تسويق وترويج ثلاثي مربح بين TiktokShop والتجار والمستهلكين. إلى جانب أحدث تقنيات P2P blockchain, يتم ربط المستهلكين والتجار بسرعة من خلال USDT (TRC20, ERC20). يمكن للمستخدمين المسجلين الحصول على عمولات على الطلبات, بينما يمكن للتجار زيادة بيانات مبيعات متجرهم. إنه أحدث نموذج ربح في نموذج blockchain على الإنترنت! ",
    'bz8': "2.كيف يعمل TiktokShop؟",
    'bz9': "توضح التعليقات اليومية من TiktokShop أن المنتجات التي تحتاج إلى تحسين يتم بيعها في TiktokShop. يحتاج المستخدمون فقط إلى النقر بسهولة على الطلب, ويقوم النظام تلقائيًا بإنشاء اشتراك الطلب. يدفع المستخدمون مبلغ الطلب عبر blockchain USDT ويتلقون عمولات يومية. ",
    'bz10': "3. لماذا تختلف أسعار نفس العملة في عمليتين؟ ",
    'bz11': "ترجع فروق الأسعار إلى جميع العوامل التي لا تساعد على حرية تداول العملات, بما في ذلك سرعة تحويل العملات, وظروف الشبكة, وقيود الوصول إلى العملة, والتعرف على العملات من قبل الأشخاص في مناطق مختلفة, وحتى أزواج التداول. التي تقدمها أنواع التبادلات والمعاملات, وما إلى ذلك. ولذلك, قد يكون لنفس العملة اختلافات في الأسعار في المعاملات المختلفة. ",
    'bz12': "4. ربح الاستثمار؟ ",
    'bz13': "كلما ارتفع سعر المنتج الذي تحصل عليه, زاد ربح الطلب الذي تحصل عليه. وفي الوقت نفسه, تقوم مراجعة RoBoT بتوزيع أوامر العمولات الكبيرة بشكل عشوائي. ",
    'bz14': "كل يوم, يقوم النظام تلقائيًا بإنشاء وتوزيع 60 طلب منتج على المستخدمين. يقوم المستخدمون بإكمال الدفع لكل طلب من خلال USDT ويحصلون على عمولة بنسبة 0.6%. يتم تعيين أوامر العمولة الكبيرة بشكل عشوائي. ",
    'bz15': "يمكن سحب USDT بعد 60 أمرًا. (إذا لم يتم إكمال 60 طلبًا, سيتوقف النظام تلقائيًا حتى وصول الطلبات المتبقية لليوم)' ",
    'bz16': "يمكن ربط كل حساب بحساب USDT واحد فقط. في حالة حدوث خطأ, يرجى الاتصال بخدمة العملاء",
    'bz17': 'بعد الانتهاء من تسليم الطلبات اليومية, يمكنك سحب الأموال بشكل طبيعي. وستصل خلال 24 ساعة من بدء السحب, ولا يوجد حد للسحب',
    'bz18': "قبل إعادة الشحن، يرجى ربط عنوان السحب الخاص بك (يدعم TRC-20) بعنوان USDT. يمكنك النقر فوق إعادة الشحن على التطبيق أو صفحة الويب واختيار blockchain (TRC-20) الذي تستخدمه لإعادة الشحن.",
    'bz19': 'لا يُسمح بإعادة شحن الأموال أو تحويلها بين الحسابات المرتبطة. هذا سلوك ضار للاستيلاء على الأوامر ويؤثر بشكل خطير على قواعد تشغيل النظام الأساسي. إذا تم العثور على مثل هذا السلوك، فسيقوم النظام بفرض غرامة على الحساب ومعاقبته في المرة الأولى، سيؤدي السلوك الخبيث المتكرر للاستيلاء على 15% من أموال الحساب إلى حرمانك من العمل.',
    'bz20': 'تحتاج الحسابات المرتبطة إلى إكمال مهام الطلب في حساب كل يوم قبل أن تتمكن من متابعة تحديث مهام الطلب لليوم التالي. إذا كانت هناك مهمة غير مكتملة بين الحسابات المرتبطة، فسيتوقف تحديث مهام الطلب حتى يتم الانتهاء من جميع مهام الطلب، تحديث مهمة الطلب',
    // نص الوحدة النمطية
    //كلمات شائعة
    'ty1': 'موافق',
    'ty2': 'إلغاء',
    'ty3': 'إرسال',
    'ty4': 'العنوان',

    //الصفحة الأمامية
    's1': 'الدخل التراكمي',
    's2': 'الخدمة',
    's3': 'نبذة عنا',
    's4': 'مساعدة',
    's5': 'شريك',
    's6': 'خدمتي',
    //طلب
    'd1': 'غير مكتمل',
    'd2': 'كامل',
    'd3': 'لا توجد بيانات حتى الآن',
    'd4': 'رقم فردي',
    'd5': 'وقت التداول',
    'd6': 'المبلغ',
    'd7': 'العدد الحالي للمهام',
    'd8': 'العائد المتوقع',
    'd9': 'بحاجة إلى المزيد',
    'd10': 'تقييم',
    //تجارة
    'j1': 'رصيد الحساب',
    'j2': 'مقدمة التداول',
    'j3': "تستخدم مراجعة RoBoT تعليقات TiktokShop كل يوم لإظهار المنتجات المباعة بواسطة TiktokShop والتي تحتاج إلى تحسين. يحتاج المستخدمون فقط إلى النقر فوق الطلب وسيقوم النظام تلقائيًا بإنشاء توقيع الطلب. يدفع المستخدمون مبلغ الطلب من خلال blockchain USDT ويمكنهم الحصول على عمولة تزيد عن 0.6٪ في كل مرة, ويقوم النظام بتوزيع أوامر المكافآت الكبيرة بشكل عشوائي. ",
    'j4': 'بدء المطابقة',
    'j5': "أرباح اليوم",
    'j6': 'الرقم مكتمل',
    'j7': 'إجمالي عدد المهام',
    //فريق
    'td1': 'الرصيد',
    'td2': 'عمولة',
    'td3': 'تلقي',
    'td4': 'إجمالي عدد الأشخاص',
    'td5': 'مستخدم',
    'td6': 'مساهمة',
    'td7': 'الكمية',
    'td8': 'الحالة',
    //المركز الشخصي
    'g1': 'انسحاب',
    'g2': 'إعادة الشحن',
    'g3': 'دعوة الأصدقاء',
    'g4': 'أدعو الآن',
    'g5': 'مقابل كل ربح يحققه صديقك بعد التسجيل, سوف تتلقى نسبة مقابلة من العمولة',
    'g6': 'المعلومات الشخصية',
    'g7': 'تفاصيل الصندوق',
    'g8': 'سجل إعادة الشحن',
    'g9': 'سجل السحب',
    'g10': 'اللغة',
    'g11': 'تسجيل الخروج',
    'g12': 'هل أنت متأكد من أنك تريد تسجيل الخروج؟',
    'g13': 'خروج',
    "g14": "تبديل الحساب",
    //معلومات المستخدم
    'yh1': 'معلومات المستخدم',
    'yh2': 'تعديل',
    'yh3': 'الإعداد',
    'yh4': 'هاتف',
    'yh5': 'هل أنت متأكد من أنك تريد تسجيل الخروج؟',
    'yh6': 'كلمة المرور',
    'yh7': 'كلمة المرور الجديدة',
    'yh8': 'يُرجى الاتصال بخدمة العملاء لتغيير كلمة المرور الخاصة بك',
    'yh9': 'الرجاء إدخال كلمة المرور الأصلية',
    'yh10': 'الرجاء إدخال كلمة المرور الجديدة',
    //تفاصيل
    'mx1': 'تحصيل العمولة',
    'mx2': 'الربح',
    'mx3': 'تم رفض السحب',
    'mx4': 'الوقت',
    'mx5': 'تم إعادة الشحن بنجاح',
    'mx6': 'مرفوض',
    'mx7': 'معلق',
    'mx8': 'عنوان السحب',
    'mx9': 'الرصيد المتاح',
    'mx10': 'الوصول الفعلي',
    'mx11': 'الرجاء إدخال مبلغ السحب',
    //فوق حتى
    'cz1': 'عنوان الإيداع يدعم فقط ERC20, والحد الأدنى لمبلغ الإيداع هو 20 دولارًا أمريكيًا',
    'cz2': 'نسخ',
    'cz3': 'اختيار العملة الورقية',
    'cz4': 'الدفع',
    'cz5': 'عنوان الإيداع يدعم فقط TRC20-USDT, والحد الأدنى لمبلغ الإيداع هو 20USDT',
    'cz6': 'عنوان الإيداع يدعم فقط ERC20, والحد الأدنى لمبلغ الإيداع هو 20 دولارًا أمريكيًا',
    'cz1': 'عنوان الإيداع يدعم فقط ERC20, والحد الأدنى لمبلغ الإيداع هو 20 دولارًا أمريكيًا',
    'cz1': 'عنوان الإيداع يدعم فقط ERC20, والحد الأدنى لمبلغ الإيداع هو 20 دولارًا أمريكيًا',
    //منطقة
    'qy1': 'رمز المنطقة',
    'yq1': 'دعوة الأصدقاء',
    'yq2': 'رمز الدعوة',
    'yq3': 'قم بدعوة الأصدقاء واستمتع بمزايا كبيرة',
    'yq4': 'اذهب وادعو',
}