//pl波兰
export const pl = {
    //Pasek nawigacyjny
    "n1": "Dom",
    "n2": "Zamówienie",
    'n3': 'Transakcja',
    "n4": "Zespół",
    "n5": "mój",
    //klasa podpowiedzi
    't1': 'Wystąpił błąd',
    't2': 'Kopiowanie powiodło się',
    't3': 'Proszę uzupełnić informacje',
    't4': 'Ładowanie...',
    't5': 'Logowanie powiodło się',
    't6': 'Wynik musi być większy niż 3, aby zostać przesłanym',
    't7': 'Przesłanie zakończone sukcesem',
    't8': 'Niewystarczające saldo, brak możliwości odbioru',
    't9': 'Przesłanie nie powiodło się',
    't10': 'Najpierw powiąż adres, a zostaniesz przekierowany na stronę centrum osobistego',
    't11': 'Adres został powiązany, prosimy o kontakt z obsługą klienta w celu modyfikacji',
    't12': 'Modyfikacja udana',
    't13': 'Modyfikacja nie powiodła się',
    't14': 'Skontaktuj się z obsługą klienta w celu zmiany hasła',
    't15': 'Błąd formatu hasła',
    't16': 'Wypłata udana',
    "t17": "Maksymalna liczba wypłat",
    "t18": "Minimalna wypłata",
    't19': 'Dwa hasła są niespójne',
    "t20": "Podpowiedź",
    't21': 'Chwilowo nie można skoczyć',
    't22': 'Błąd formatu hasła',
    't23': 'Rejestracja udana',
    't24': 'Proszę podać numer kierunkowy',
    "t25": "Nigdy więcej",
    "t26": "powrót",
    "t27": "recenzja",
    't28': 'Audyt udany',
    't29': 'Oczekiwanie na sprawdzenie i przetworzenie',
    't30': 'Audyt nie powiódł się',
    't31': 'Proszę wpisać adres',
    't32': 'Proszę wpisać swój nick',
    't33': 'Wystąpił błąd. Proszę wyjść z programu i spróbować ponownie. ',
    "t34": "Niedawno odkryto, że wiele osób podszywa się pod Tiktok w celu popełnienia oszustwa. Poszukaj naszej oficjalnej aplikacji",
    //zapisać się
    "l1": "Język",
    'l2': 'Proszę podać numer swojego konta',
    'l3': 'Proszę podać hasło',
    "l4": "pamiętaj",
    'l5': 'Zaloguj się',
    'l6': 'Przejdź do rejestracji',
    'l7': 'Brak konta',
    'l8': 'Numer telefonu komórkowego',
    "l9": "pseudonim",
    'l10': 'hasło',
    'l11': 'Potwierdź hasło',
    'l12': 'kod zaproszenia',
    'l13': 'Zarejestruj się',
    'l14': 'Mam już konto',
    'l15': 'Przejdź do logowania',
    'l16': 'Wprowadź hasło składające się z 6-16 cyfr',
    'l17': 'Błąd formatu liczby',
    //pomoc
    'bz1': 'Pomoc',
    "bz2": "FAQ",
    'bz3': 'Zasady handlu',
    'bz4': 'Jak wypłacić pieniądze',
    'bz5': 'Jak doładować',
    'bz6': '1.Co to jest TiktokShop?',
    "bz7": "TiktokShop to firma zajmująca się marketingiem i promocją, która pomaga sprzedawcom w sklepach internetowych TiktokShop na całym świecie uzyskać większą sprzedaż zamówień i zwiększyć ilość danych przeglądania sklepu internetowego TiktokShop. Zależy nam na ustanowieniu trójstronnego, zyskownego modelu marketingu i promocji pomiędzy TiktokShop, sprzedawcami i konsumentami. W połączeniu z najnowszą technologią blockchain P2P, konsumenci i sprzedawcy są szybko połączeni za pośrednictwem USDT (TRC20, ERC20). Zarejestrowani użytkownicy mogą otrzymywać prowizje od zamówień, a sprzedawcy mogą zwiększać dane sprzedażowe swojego sklepu. To najnowszy model zysku w internetowym modelu blockchain! ",
    'bz8': '2.Jak działa TiktokShop?',
    'bz9': 'Codzienne informacje zwrotne z TiktokShop pokazują, że produkty wymagające poprawy są sprzedawane w TiktokShop. Użytkownicy muszą jedynie łatwo kliknąć zamówienie, a system automatycznie generuje subskrypcję zamówienia. Użytkownicy płacą kwotę zamówienia za pośrednictwem blockchain USDT i otrzymują dzienną prowizję. ',
    'bz10': '3.Dlaczego ceny tej samej waluty są różne w dwóch transakcjach? ',
    "bz11": "Różnice cenowe są spowodowane wszystkimi czynnikami, które nie sprzyjają swobodnemu obiegowi walut, w tym szybkością transferów walut, warunkami sieci, ograniczeniami w dostępie do waluty, rozpoznawaniem walut przez ludzi w różnych regionach, a nawet parami handlowymi dostarczane przez typy giełd, transakcje itp. Dlatego ta sama waluta może mieć różnice cenowe w różnych transakcjach. ",
    'bz12': '4. Zysk z inwestycji? ',
    'bz13': 'Im wyższą cenę produktu uzyskasz, tym większy zysk uzyskasz z zamówienia. Jednocześnie TiktokShop losowo rozdziela duże zamówienia prowizyjne. ',
    'bz14': "System automatycznie generuje i rozsyła do użytkowników 60 zamówień produktów dziennie. Użytkownicy dokonują płatności za każde zamówienie za pośrednictwem USDT i otrzymują 0.6% prowizji. Duże zamówienia prowizyjne są przydzielane losowo. ",
    'bz15': "USDT można wypłacić po złożeniu 60 zleceń. (Jeżeli 60 zamówień nie zostanie zrealizowanych, system automatycznie zatrzyma się do czasu realizacji pozostałych zamówień danego dnia)",
    'bz16': 'Każde konto może być powiązane tylko z jednym kontem USDT. W przypadku wystąpienia błędu prosimy o kontakt z obsługą klienta',
    "bz17": "Po zrealizowaniu codziennych zamówień możesz normalnie wypłacić pieniądze. Dotrą one w ciągu 24 godzin od rozpoczęcia wypłaty i nie ma limitu wypłat",
    "bz18": "Przed doładowaniem powiąż swój adres wypłaty (obsługuje TRC-20) z adresem USDT. Możesz kliknąć Doładuj w aplikacji lub na stronie internetowej i wybrać łańcuch bloków (TRC-20), którego używasz do doładowania. ",
    "bz19": "Doładowanie lub przesyłanie środków pomiędzy powiązanymi kontami jest niedozwolone.Jest to złośliwe przejmowanie zamówień i poważnie wpływa na zasady działania platformy.W przypadku wykrycia takiego zachowania system nałoży karę na konto.Pierwsza kara zostanie zostanie nałożona kara w wysokości 15 % środków na koncie.Powtarzające się złośliwe zachowanie polegające na przejmowaniu zamówień spowoduje bezpośrednią dyskwalifikację z pracy",
    'bz20': "Powiązane konta muszą wykonać zadania dotyczące zamówień na koncie każdego dnia, zanim będą mogły kontynuować aktualizację zadań związanych z zamówieniami na następny dzień. Jeśli pomiędzy powiązanymi kontami znajduje się niedokończone zadanie, aktualizacja zadań związanych z zamówieniami zostanie zatrzymana do czasu zakończenia wszystkich zadań zamówienia Aktualizuj zadanie zamówienia",
    //tekst modułu
    //Pospolite słowa
    "ty1": "OK",
    "ty2": "Anuluj",
    "ty3": "Wyślij",
    "ty4": "adres",
    //pierwsza strona
    "s1": "Skumulowany dochód",
    "s2": "Usługa",
    's3': 'O nas',
    "s4": "Pomoc",
    "s5": "Partner",
    's6': 'Mój serwis',
    //Zamówienie
    "d1": "Niedokończone",
    "d2": "Zakończono",
    'd3': 'Jeszcze brak danych',
    'd4': 'liczba nieparzysta',
    "d5": "Czas handlu",
    "d6": "kwota",
    'd7': 'Aktualna liczba zadań',
    "d8": "Oczekiwany zwrot",
    "d9": "Potrzebuję więcej",
    'd10': 'Ocena',
    //handel
    'j1': 'Saldo konta',
    "j2": "Wprowadzenie do handlu",
    "j3": "Recenzja RoBoT codziennie korzysta z informacji zwrotnych z recenzji RoBoT, aby pokazać produkty sprzedawane przez firmę TiktokShop, które wymagają ulepszenia. Użytkownik musi jedynie kliknąć zamówienie, a system automatycznie wygeneruje podpis zamówienia. Użytkownicy płacą kwotę zamówienia za pośrednictwem łańcucha bloków USDT i za każdym razem mogą otrzymać ponad 0,6% prowizji, a system losowo rozdziela duże zamówienia z nagrodami. ",
    'j4': 'Rozpocznij dopasowywanie',
    'j5': 'Dzisiejsze zarobki',
    'j6': 'Numer ukończony',
    'j7': 'Całkowita liczba zadań',
    //zespół
    "td1": "Saldo",
    "td2": "prowizja",
    "td3": "Odbierz",
    'td4': 'Całkowita liczba osób',
    "td5": "użytkownik",
    "td6": "wkład",
    'td7': 'Ilość',
    "td8": "status",
    //Centrum osobiste
    'g1': 'Wypłata',
    "g2": "Naładuj",
    'g3': 'Zaproś znajomych',
    'g4': 'Zaproś teraz',
    'g5': 'Za każdy zysk, jaki Twój znajomy osiągnie po rejestracji, otrzymasz odpowiednią część prowizji',
    "g6": "Dane osobowe",
    'g7': 'Szczegóły funduszu',
    'g8': 'Rekord doładowania',
    'g9': 'Rekord wypłaty',
    'g10': 'Język',
    'g11': 'Wyloguj się',
    'g12': 'Czy na pewno chcesz się wylogować?',
    'g13': 'Wyjście',
    "g14": "Przełącz konto",
    //Informacje użytkownika
    'yh1': 'Informacje o użytkowniku',
    "yh2": "Modyfikuj",
    "yh3": "ustawienie",
    "yh4": "telefon",
    'yh5': 'Czy na pewno chcesz się wylogować?',
    'yh6': 'Hasło',
    'yh7': 'Nowe hasło',
    'yh8': 'Skontaktuj się z obsługą klienta w celu zmiany hasła',
    'yh9': 'Proszę podać oryginalne hasło',
    'yh10': 'Proszę podać nowe hasło',
    //Detale
    'mx1': 'Pobór prowizji',
    "mx2": "zysk",
    'mx3': 'Wypłata odrzucona',
    "mx4": "czas",
    'mx5': 'Doładowanie powiodło się',
    "mx6": "Odrzucono",
    "mx7": "oczekuje",
    'mx8': 'Adres wypłaty',
    'mx9': 'Dostępne saldo',
    "mx10": "Rzeczywiste przybycie",
    'mx11': 'Proszę podać kwotę wypłaty',
    //uzupełniać
    'cz1': 'Adres wpłaty obsługuje tylko ERC20, a minimalna kwota wpłaty to 20USDT',
    'cz2': 'Kopiuj',
    'cz3': 'Wybór waluty Fiata',
    'cz4': 'płatność',
    'cz5': 'Adres wpłaty obsługuje tylko TRC20-USDT, a minimalna kwota wpłaty to 20USDT',
    'cz6': 'Adres wpłaty obsługuje tylko ERC20, a minimalna kwota wpłaty to 20USDT',
    'cz1': 'Adres wpłaty obsługuje tylko ERC20, a minimalna kwota wpłaty to 20USDT',
    'cz1': 'Adres wpłaty obsługuje tylko ERC20, a minimalna kwota wpłaty to 20USDT',
    //obszar
    "qy1": "numer kierunkowy",
    'yq1': 'Zaproś znajomych',
    'yq2': 'kod zaproszenia',
    'yq3': 'Zaproś znajomych i ciesz się dużymi korzyściami',
    'yq4': 'Idź i zaproś',
}
