//da丹麦
export const da = {
    //Navigations bar
    'n1': 'Hjem',
    'n2': 'Ordre',
    'n3': 'Transaktion',
    'n4': 'Team',
    'n5': 'min',
    //prompt klasse
    't1': 'Der opstod en fejl',
    't2': 'Kopiering lykkedes',
    't3': 'Fyld venligst oplysningerne fuldstændigt',
    't4': 'Indlæser...',
    't5': 'Login lykkedes',
    't6': 'Score skal være større end 3 for at indsende',
    't7': 'Indsendelse lykkedes',
    't8': 'Utilstrækkelig saldo, ude af stand til at modtage',
    't9': 'Indsendelse mislykkedes',
    't10': 'Bind venligst adressen først, og du vil blive omdirigeret til den personlige centerside',
    't11': 'Adressen er blevet bundet, kontakt venligst kundeservice for at ændre den',
    't12': 'Ændring lykkedes',
    't13': 'Ændring mislykkedes',
    't14': 'Kontakt kundeservice for at ændre din adgangskode',
    't15': 'Fejl ved kodeordsformat',
    't16': 'Tilbagetrækning lykkedes',
    't17': 'Maksimale hævninger',
    't18': 'Minimum tilbagetrækning',
    't19': 'To adgangskoder er inkonsistente',
    't20': 'Spørg',
    't21': 'Kan ikke springe midlertidigt',
    't22': 'Fejl ved kodeordsformat',
    't23': 'Registrering lykkedes',
    't24': 'Indtast venligst områdenummer',
    't25': 'Ikke mere',
    't26': 'Vend tilbage',
    't27': 'anmeldelse',
    't28': 'Revision lykkedes',
    't29': 'Venter på gennemgang og behandling',
    't30': 'Revision mislykkedes',
    't31': 'Fyld venligst adressen',
    't32': 'Fyld venligst dit kaldenavn',
    't33': 'Der opstod en fejl. Afslut programmet og prøv igen. ',
    't34': 'For nylig er det blevet opdaget, at mange mennesker udgiver sig for at være Tiktok for at begå svindel. Se venligst efter vores officielle APP',
    //Tilmelde
    'l1': 'Sprog',
    'l2': 'Indtast venligst dit kontonummer',
    'l3': 'Indtast venligst adgangskode',
    'l4': 'husk',
    'l5': 'Login',
    'l6': 'Gå til registrering',
    'l7': 'Ingen konto',
    'l8': 'Mobilnummer',
    'l9': 'kaldenavn',
    'l10': 'adgangskode',
    'l11': 'Bekræft adgangskode',
    'l12': 'invitationskode',
    'l13': 'Registrer',
    'l14': 'Har allerede en konto',
    'l15': 'Gå til log ind',
    'l16': 'Indtast venligst en 6-16 cifret adgangskode',
    'l17': 'Fejl i nummerformat',
    //Hjælp
    'bz1': 'Hjælp',
    'bz2': 'Ofte stillede spørgsmål',
    'bz3': 'Handelsregler',
    'bz4': 'Sådan hæver man penge',
    'bz5': 'Sådan genoplades',
    'bz6': '1.Hvad er TiktokShop?',
    'bz7': 'TiktokShop er et marketing- og promoveringsfirma, der hjælper TiktokShop-onlinebutikker over hele verden med at få flere ordresalg og øge browserdataene i TiktokShop-onlinebutikker. Vi er forpligtet til at etablere en treparts profitabel marketing- og promoveringsmodel mellem TiktokShop, købmænd og forbrugere. Kombineret med den nyeste P2P blockchain-teknologi er forbrugere og handlende hurtigt forbundet via USDT (TRC20, ERC20). Registrerede brugere kan få provision på ordrer, mens handlende kan øge deres butiks salgsdata. Det er den seneste profitmodel i Internet blockchain-modellen! ',
    'bz8': '2.Hvordan fungerer TiktokShop?',
    'bz9': 'Den daglige feedback fra TiktokShop viser, at produkter, der skal forbedres, sælges i TiktokShop. Brugerne skal kun nemt klikke på ordren, og systemet genererer automatisk et ordreabonnement. Brugere betaler ordrebeløbet via blockchain USDT og modtager daglige provisioner. ',
    'bz10': '3. Hvorfor er priserne på den samme valuta forskellige i to transaktioner? ',
    'bz11': 'Prisforskelle er forårsaget af alle faktorer, der ikke er befordrende for den frie cirkulation af valutaer, herunder hastigheden af ​​valutaoverførsler, netværksforhold, valutaadgangsrestriktioner, valutagenkendelse af folk i forskellige regioner og endda handelsparrene leveret af børstyper, transaktioner mv. Derfor kan den samme valuta have prisforskelle i forskellige transaktioner. ',
    'bz12': '4. Investeringsoverskud? ',
    'bz13': 'Jo højere produktpris du får, jo højere ordrefortjeneste får du. Samtidig fordeler TiktokShop tilfældigt store kommissionsordrer. ',
    'bz14': 'Hver dag genererer og distribuerer systemet automatisk 60 produktordrer til brugere. Brugere gennemfører betalingen for hver ordre gennem USDT og modtager en kommission på 0.6%. Store kommissionsordrer tildeles tilfældigt. ',
    'bz15': 'USDT kan trækkes tilbage efter 60 ordrer. (Hvis 60 ordrer ikke gennemføres, stopper systemet automatisk indtil de resterende ordrer på dagen)',
    'bz16': 'Hver konto kan kun være bundet til én USDT-konto. Hvis der opstår en fejl, bedes du kontakte kundeservice',
    'bz17': 'Efter at have afsluttet leveringen af ​​daglige ordrer, kan du hæve penge normalt. De vil ankomme inden for 24 timer efter start af tilbagetrækningen, og der er ingen udbetalingsgrænse',
    'bz18': "Bind venligst din udbetalingsadresse (understøtter TRC-20) USDT-adresse før genopladning. Du kan klikke på Genoplad på APP'en eller websiden og vælge den blockchain (TRC-20), du bruger til at genoplade. ",
    'bz19': 'Genopladning eller overførsel af midler mellem tilknyttede konti er ikke tilladt. Dette er en ondsindet ordremodtagelse og påvirker i høj grad platformens driftsregler. Hvis en sådan adfærd bliver fundet, vil systemet bøde kontoen og straffe den første gang 15 % af kontoens midler, vil gentagen ondsindet ordremodtagelse direkte diskvalificere dig fra at arbejde',
    'bz20': 'Tilknyttede konti skal udføre ordreopgaverne på hver dags konto, før de kan fortsætte med at opdatere ordreopgaverne for den næste dag. Hvis der er en uafsluttet opgave mellem de tilknyttede konti, stopper opdateringen af ​​ordreopgaverne indtil alle ordreopgaver er udført. Opdater ordreopgave',
    //modultekst
    //Almindelige ord
    'ty1': 'OK',
    'ty2': 'Annuller',
    'ty3': 'Send',
    'ty4': 'adresse',
    //Forside
    's1': 'Kumuleret indkomst',
    's2': 'Service',
    's3': 'Om os',
    's4': 'Hjælp',
    's5': 'Partner',
    's6': 'Min service',
    //Bestille
    'd1': 'Ufærdig',
    'd2': 'Afsluttet',
    'd3': 'Ingen data endnu',
    'd4': 'ulige tal',
    'd5': 'Handelstid',
    'd6': 'Beløb',
    'd7': 'Aktuelt antal opgaver',
    'd8': 'Forventet afkast',
    'd9': 'Har brug for mere',
    'd10': 'Bedømmelse',
    //handle
    'j1': 'Kontosaldo',
    'j2': 'Handelsintroduktion',
    'j3': 'TiktokShop bruger TiktokShops feedback hver dag til at vise de produkter, der sælges af TiktokShop, og som skal forbedres. Brugere behøver kun at klikke på ordren, og systemet genererer automatisk en ordresignatur. Brugere betaler ordrebeløbet gennem USDT blockchain og kan modtage mere end 0,6% kommission hver gang, og systemet distribuerer tilfældigt store belønningsordrer. ',
    'j4': 'Start matching',
    'j5': 'Dagens indtjening',
    'j6': 'Nummer fuldført',
    'j7': 'Samlet antal opgaver',
    //hold
    'td1': 'Balance',
    'td2': 'kommission',
    'td3': 'Modtag',
    'td4': 'Samlet antal personer',
    'td5': 'bruger',
    'td6': 'bidrag',
    'td7': 'Mængde',
    'td8': 'status',
    //Personcenter
    'g1': 'Tilbagekald',
    'g2': 'Genoplad',
    'g3': 'Inviter venner',
    'g4': 'Inviter nu',
    'g5': 'For hver fortjeneste, din ven tjener efter at have registreret sig, vil du modtage en tilsvarende andel af kommission',
    'g6': 'Personlige oplysninger',
    'g7': 'Fondsoplysninger',
    'g8': 'Recharge record',
    'g9': 'Tilbagetrækningsregistrering',
    'g10': 'Sprog',
    'g11': 'Log ud',
    'g12': 'Er du sikker på, at du vil logge ud?',
    'g13': 'Afslut',
    "g14": "Skift konto",
    //Bruger info
    'yh1': 'Brugeroplysninger',
    'yh2': 'Rediger',
    'yh3': 'indstilling',
    'yh4': 'telefon',
    'yh5': 'Er du sikker på, at du vil logge ud?',
    'yh6': 'Adgangskode',
    'yh7': 'Ny adgangskode',
    'yh8': 'Kontakt kundeservice for at ændre din adgangskode',
    'yh9': 'Fyld venligst den originale adgangskode',
    'yh10': 'Fyld venligst den nye adgangskode',
    //Detaljer
    'mx1': 'Provisionsopkrævning',
    'mx2': 'profit',
    'mx3': 'Tilbagetrækning afvist',
    'mx4': 'tid',
    'mx5': 'Opladning lykkedes',
    'mx6': 'Afvist',
    'mx7': 'afventer',
    'mx8': 'Tilbagetrækningsadresse',
    'mx9': 'Tilgængelig saldo',
    'mx10': 'Faktisk ankomst',
    'mx11': 'Indtast venligst udbetalingsbeløbet',
    //fyld op
    'cz1': 'Indbetalingsadressen understøtter kun ERC20, og minimumsindbetalingsbeløbet er 20USDT',
    'cz2': 'Kopier',
    'cz3': 'Fiat valutavalg',
    'cz4': 'betaling',
    'cz5': 'Indbetalingsadressen understøtter kun TRC20-USDT, og minimumsindbetalingsbeløbet er 20USDT',
    'cz6': 'Indbetalingsadressen understøtter kun ERC20, og minimumsbeløbet er 20USDT',
    'cz1': 'Indbetalingsadressen understøtter kun ERC20, og minimumsindbetalingsbeløbet er 20USDT',
    'cz1': 'Indbetalingsadressen understøtter kun ERC20, og minimumsindbetalingsbeløbet er 20USDT',
    //areal
    'qy1': 'områdenummer',
    'yq1': 'Inviter venner',
    'yq2': 'invitationskode',
    'yq3': 'Inviter venner og nyd store fordele',
    'yq4': 'Gå og inviter',
}